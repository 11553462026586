import React from 'react';

const Oops = () => {
    return (
      <div>
        <img src="columbo.png" alt="oops" style={{ maxWidth: '100%', height: 'auto' }} />
      </div>
    );
}

export default Oops;