import React, { useState } from 'react';
import './CardForm.css';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Address from './Address';

const CardForm = ({ card, onSubmit }) => {

  const [firstName, setFirstName] = useState(card ? card.first_name : '');
  const [lastName, setLastName] = useState(card ? card.last_name : '');

  const [addressLine1, setAddressLine1] = useState(card ? card.address_line1 : '');
  const [addressLine2, setAddressLine2] = useState(card ? card.address_line2 : '');
  const [city, setCity] = useState(card ? card.city : '');
  const [postalCode, setPostalCode] = useState(card ? card.postal_code : '');
  const [country, setCountry] = useState(card ? card.country : '');

  const [email, setEmail] = useState(card ? card.email : '');
  const [stampCode] = useState(card ? card.stamp_code : '');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit({
        first_name: firstName,
        last_name: lastName,
        email: email,
        address_line1: addressLine1,
        address_line2: addressLine2,
        city: city,
        postal_code: postalCode,
        country: country,
        stamp_code: stampCode
      });
    }
  }

  return (
    <div className='form'>
      <form onSubmit={handleSubmit}>
        <div>
          {card ? <>
            <label>First and last name</label>
            <input
              id="firstName"
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder='first name'
              disabled={!onSubmit}
            />
            <input
              id="lastName"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder='last name'
              disabled={!onSubmit}
            />
          </> : <Skeleton count={3} />}
        </div>
        <Address  id={"addressSearch"} 
                  card={{
                    address_line1: addressLine1,
                    address_line2: addressLine2,
                    city: city,
                    postal_code: postalCode,
                    country: country
                  }}
                  setAddressLine1={setAddressLine1}
                  setAddressLine2={setAddressLine2}
                  setCity={setCity}
                  setPostalCode={setPostalCode}
                  setCountry={setCountry}
                  loaded={card}
                  disabled={!onSubmit} 
                  enablePlacesAPI={process.env.REACT_APP_PLACES_API_KEY}/>
        <div>
          {card ? <>
            <label>Email</label>
            <input
              id="email"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder='@'
              disabled={!onSubmit}
            />
          </> : <Skeleton count={2} />}
        </div>
        <button type="submit" className="submit" id="submit" disabled={!onSubmit}>
          {onSubmit ? 'Submit' : <div className="spinner"></div>}
        </button>
      </form>
    </div>

  );
};

export default CardForm;
