import { configureStore } from '@reduxjs/toolkit'
import cardReducer from '../features/card/cardSlice'
import sessionReducer from '../features/session/sessionSlice'


const store = configureStore({
reducer: {
   card: cardReducer,
   session: sessionReducer
}
})

export default store
