import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import supabase from '../../utils/supabaseClient';
import * as Sentry from "@sentry/browser";

export const isValidGUID = (guid) => {
  const guidRegex = /^[0-9a-fA-F]{8}-?[0-9a-fA-F]{4}-?[0-9a-fA-F]{4}-?[0-9a-fA-F]{4}-?[0-9a-fA-F]{12}$/;
  return guidRegex.test(guid);
};

export const postCardWithStamp = createAsyncThunk(
    'card/postCardWithStamp',
    async (card, { rejectWithValue }) => {

      const { data, error } = await supabase
        .from('cards')
        .update({ first_name: card.first_name, 
                  last_name: card.last_name, 
                  email: card.email, 
                  address_line1: card.address_line1, 
                  address_line2: card.address_line2, 
                  city: card.city, 
                  postal_code: card.postal_code, 
                  country: card.country })
        .eq('stamp_code', card.stamp_code)
        .select();
//      await new Promise(resolve => setTimeout(resolve, 3000));
      if (error) {
        console.log(error);
        console.log(card);
        Sentry.captureException(new Error(`Card update for card.stamp_code: ${card.stamp_code} failed. PostgrestError.code: ${error.code} PostgrestError.message: ${error.message}`));
        return rejectWithValue("PostgrestError")
      }
      Sentry.captureMessage(`Card received:
        stamp_code    ${card.stamp_code},
        name          ${card.first_name} ${card.last_name}
        email         ${card.email}
        address line1 ${card.address_line1}
        address line2 ${card.address_line2}
        city          ${card.city}
        postal code   ${card.postal_code}
        country       ${card.country}`);
      return data[0];
    }
);

export const searchCardsWithStamp = createAsyncThunk(
    'card/searchCardsWithStamp',
    async (stampCode, { rejectWithValue }) => {

        const { data: cards, error } = await supabase
          .from('cards').select("*")
          .eq('stamp_code', stampCode);
          if (error) {
            Sentry.captureException(new Error(`Search for card with stamp_code: ${stampCode} failed. PostgrestError.code: ${error.code} PostgrestError.message: ${error.message}`));
            return rejectWithValue("PostgrestError");
          }
          if (cards.length === 0) {
            Sentry.captureException(new Error(`Search for card with stamp_code: ${stampCode} did not return a card.`));
            return rejectWithValue("CardNotFound");
          }
          return cards;
    }
);

export const CARD_STATUS = {
    LOADING: 'LOADING',
    LOADED: 'LOADED',
    POSTING: 'POSTING',
    POSTED: 'POSTED',
    ERROR: 'ERROR'
};

export const cardSlice = createSlice({
    name: 'card',
    initialState: {
        card: null,
        status: CARD_STATUS.LOADING,
    },
    reducers: {
      updateCard: (state, action) => {
          state.card = action.payload;
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(searchCardsWithStamp.rejected, (state) => {
          state.status = CARD_STATUS.ERROR;
          state.card = null;
        })
        .addCase(postCardWithStamp.rejected, (state) => {
          state.status = CARD_STATUS.ERROR;
          state.card = null;
        })
        .addCase(searchCardsWithStamp.pending, (state) => {
          state.status = CARD_STATUS.LOADING;
          state.card = null;
        })
        .addCase(searchCardsWithStamp.fulfilled, (state, action) => {
          state.status = CARD_STATUS.LOADED;
          if (action.payload.length > 0) {
            state.card = action.payload[0];
          }
        })
        .addCase(postCardWithStamp.pending, (state) => {
          state.status = CARD_STATUS.POSTING;
        })
        .addCase(postCardWithStamp.fulfilled, (state, action) => {
          state.status = CARD_STATUS.POSTED;
          state.card = action.payload;
        })
    }
});

export const selectCard = (state) => state.card.card;
export const cardStatus = (state) => state.card.status;
export const cardErrorMessage = (state) => state.card.errorMessage;

export const { updateCard } = cardSlice.actions
export default cardSlice.reducer;

