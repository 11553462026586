import React from "react";
import { Outlet } from "react-router-dom";
import "./Root.css";
import ErrorBoundary from "./ErrorBoundary";

export default function Root() {
    return (
      <>
        <main>
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </main>
      </>
    )
}