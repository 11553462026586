import React from 'react';
import './ShortMessage.css';
import 'react-loading-skeleton/dist/skeleton.css'
import { useLocation } from 'react-router-dom';

const ShortMessage = ({greeting, message, signature, code, snow}) => {

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const stampCode = query.get('stamp_code');
  return (
    <div>
    {snow && <div className="snowflakes">
      <i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i>
    </div> }
    <div className="handwritten">
      <h1 className="formatted-text">{ greeting }</h1>
      <p className="formatted-text">{ message }</p>
      {code && (
          <pre className="code-block">
            <code>stamp_code={stampCode}</code>
          </pre>
        )}
      {signature && <h2>Michał Sujka</h2>}
    </div>
    </div>

  )
};

export default ShortMessage;