import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  selectCard,
  updateCard,
  searchCardsWithStamp,
  postCardWithStamp,
  cardStatus,
  CARD_STATUS
} from './cardSlice';
import Hi from '../../components/Hi';
import CardForm from '../../components/CardForm';
import Success from '../../components/Success';
import Oops from '../../components/Oops';
import ShortMessage from '../../components/ShortMessage';
import './Card.css';
import 'react-loading-skeleton/dist/skeleton.css'

const cardGreeting = `Hi | Cześć,`;
const cardMessage = `Every year, I enjoy sending holiday cards to friends and family, and I would love to include you in that list. Please take a moment to fill out this address card so I can send it to you.
          \nIf you have already given me your address in the past and it hasn't changed, simply hit the submit button to confirm that it remains the same.
          \nFinally, I am also asking for the email so that those home address update links have an easy way to reach you in the future.
          \nWarm regards,`;

const cardErrorGreeting = `I am embarrassed to say that but ...`;
const cardErrorMessage = `It looks like we need to tie up some loose ends. Our team has been notified and will be at the sceen shortly.
                          \nJust one more thing ...
                          \nAre you sure the link in your browser matches the one you received, particularly the part containing the code?`;

const Card = () => {
  const [ searchParams ] = useSearchParams();
  const stampCode = searchParams.get('stamp_code');

  const dispatch = useDispatch();

  useEffect(() => {
    if (stampCode) {
      console.log('searching for card with stamp code', stampCode);
      dispatch(searchCardsWithStamp(stampCode));
    }
  }, [stampCode, dispatch]);

  const onSubmit = (card) => {
    dispatch(updateCard(card));
    dispatch(postCardWithStamp(card));
  };

  const card = useSelector(selectCard);
  const status = useSelector(cardStatus);

  return (

    <div className='container fade-in'>
      <div className='side column-background-1'>
      <div className='side-content'>
      {status === CARD_STATUS.ERROR && (
        
        <ShortMessage greeting={cardErrorGreeting} message={cardErrorMessage} code={true}/>
      )}
      {status !== CARD_STATUS.ERROR && status !== CARD_STATUS.POSTED && (
        <ShortMessage greeting={cardGreeting} message={cardMessage} signature={true}/>
      )}
      { status === CARD_STATUS.POSTED && (
        <ShortMessage greeting={cardGreeting} message={cardMessage} signature={true} snow={true}/>
      )}
      </div>
      </div>
      <div className='side column-background-2'>
      <div className='side-content'>
        {status === CARD_STATUS.LOADING && !stampCode && (
          <div>
            <Hi/>
          </div>
        )}
        {status === CARD_STATUS.LOADING && stampCode && (
          <div>
            <CardForm/>
          </div>
        )}
        {status === CARD_STATUS.LOADED && (
          <div>
            <CardForm card={card} onSubmit={onSubmit}/>
          </div>
        )}
        {status === CARD_STATUS.POSTING && (
          <div>
            <CardForm card={card}/>
          </div>
        )}
        {status === CARD_STATUS.POSTED && (
          <div>
            <Success card={card}/>
          </div>
        )}
        {status === CARD_STATUS.ERROR && (
          <div>
            <Oops/>
          </div>
        )}
        </div>
      </div>
      
    </div>
  );
};

export default Card;