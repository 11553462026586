// AnimatedThankYou.js

import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import './Success.css';
import Footer from './Footer';

const Success = ({card}) => {
  console.log(card);
  // Spring animation for the checkmark and text fade-in
  const checkmarkProps = useSpring({
    from: { strokeDashoffset: 100 },
    to: { strokeDashoffset: 0 },
    config: { duration: 500 },
  });

  const textPropsOne = useSpring({
    from: { opacity: 0, transform: 'scale(0.8)' },
    to: { opacity: 1, transform: 'scale(1)' },
    config: { tension: 200, friction: 15 },
    delay: 500,
  });

  const textPropsTwo = useSpring({
    from: { opacity: 0, transform: 'scale(0.8)' },
    to: { opacity: 1, transform: 'scale(1)' },
    config: { tension: 100, friction: 15 },
  });

  return (
    <div className="thank-you-container">
      <svg
        viewBox="0 0 52 52"
        className="checkmark-svg"
      >
        <animated.path
          style={checkmarkProps}
          className="checkmark"
          fill="none"
          stroke="#4CAF50"
          strokeWidth="3"
          strokeDasharray="100"
          strokeLinecap="round"
          d="M14 27l10 10 14-20"
        />
      </svg>
      <animated.div style={textPropsTwo}>
        <div className="thank-you-text">Thank you</div>
        <div className="textarea">I look forward to sending a little holiday cheer your way.</div>
      </animated.div>
      <animated.div className="envelope" style={textPropsOne}>
        <div className="envelope-address">
          { card.first_name } { card.last_name }<br />
          { card.address_line1 && <>{ card.address_line1 }<br /></> }
          { card.address_line2 && <>{ card.address_line2 }<br /></> }
          { card.city && <>{ card.city }<br /></> }
          { card.postal_code && <>{ card.postal_code }<br /></> }
          { card.country && <>{ card.country }</> }
        </div>
      </animated.div>
      <Footer />
    </div>
  );
};

export default Success;
