import './App.css';
import Card from '../features/card/Card';
import Root from '../components/Root';
import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route, Navigate} from 'react-router-dom';

const router = createBrowserRouter(createRoutesFromElements(
  <Route path="/" element={ <Root/> } errorElement={<Navigate to='/' />} >
    <Route index element={ <Card/> } />
    <Route path="cards" element={ <Card/> } />
  </Route>
));

function App() {
  return (
    <RouterProvider router={ router } />
  );
}


export default App;
