import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Hi.css';
import 'primeicons/primeicons.css';


const Hi = () => {
  const navigate = useNavigate()
  const [stampCode, setStampCode] = useState('');
  const [invalidCodeMessage, setInvalidCodeMessage] = useState('');

  const validateStampCode = (code) => {
    if (code && code.length < 32) {
      setInvalidCodeMessage('The code is incomplete.');
    } else if (code && code.length === 32 && !code.match(/^[a-zA-Z0-9]{32}$/)) {
      setInvalidCodeMessage('The code should only contain letters and numbers.');
    } else {
      setInvalidCodeMessage('');
    }
  };

  const handleInputChange = (e) => {
    setStampCode(e.target.value);
  };

  const handleKeyDown = (e) => {
    setInvalidCodeMessage('');
    if (e.key === 'Enter') {
      if (!stampCode.match(/^[a-zA-Z0-9]{32}$/)) {
        validateStampCode(stampCode);
        const inputField = e.target;

        inputField.classList.add('shake-horizontal');
        setTimeout(() => {
          inputField.classList.remove('shake-horizontal');
        }, 500);
      }
      else {
        navigate('/cards?stamp_code=' + stampCode);
      }
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!stampCode.match(/^[a-zA-Z0-9]{32}$/)) {
      validateStampCode(stampCode);
      const inputField = document.getElementById('stampCodeInput');
      inputField.classList.add('shake-horizontal');
      setTimeout(() => {
        inputField.classList.remove('shake-horizontal');
      }, 500);
    }
    else {
      navigate('/cards?stamp_code=' + stampCode);
    }
  }

  const handleFocus = () => {
    setInvalidCodeMessage('');
  };

  const handleBlur = (e) => {
    const inputField = e.target;
    if (!stampCode.match(/^[a-zA-Z0-9]{32}$/)) {
      inputField.style.borderColor = 'orange';
    }
    else {
      inputField.style.borderColor = 'black';
    }
  };

  return (
    <div>
      <div className="centre"><div className="pi pi-lock"></div></div>
      <label className="centre">Enter the secret code to open your address card</label>
      <input 
        className="centre"
        type="text" 
        value={stampCode} 
        onChange={handleInputChange} 
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder="30cf6620ce9942bd9a7098f6197a5836" 
        pattern='[a-zA-Z0-9]{32}'
        maxLength={32}
      />
      <div className="error-message centre">
          {invalidCodeMessage}
      </div>

      <button type="submit" className="submit" id="submit" onClick={handleSubmit}>
         Open
      </button>
    </div>
  );
};

export default Hi;