import  { React, useState } from "react";
import "./Footer.css";
import { useEffect } from "react";

export default function Footer() {

  const [iconIdIndex, setIconIdIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const iconIds = ["footer-icone", "footer-linkedin-icone", "footer-code-icone"];
      const icon = document.getElementById(iconIds[iconIdIndex]);
      
      if (icon) {
        icon.classList.add("shake-vertical");
        setTimeout(() => {
          icon.classList.remove("shake-vertical");
          setIconIdIndex( iconIdIndex === (iconIds.length - 1) ? 0 : iconIdIndex + 1);
        }, 1000); // Remove the class after 1 second
      }
    }, 3000); // Add the class every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [iconIdIndex]);

  return (
    <div className="footer">
      <div> Designed and built by <strong>Michał</strong> |</div>
      <a className="footer-icone" href="https://michalsujka.com" target="_blank" rel="noopener noreferrer">
        <img className="footer-icone" id="footer-icone" src="msujka.png" alt="https://michalsujka.com" />
      </a>
      <a className="footer-icone" href="https://www.linkedin.com/in/michalsujka/" target="_blank" rel="noopener noreferrer">
        <div className="footer-icone pi pi-linkedin" id="footer-linkedin-icone"></div>
      </a>
      <a className="footer-icone" href="https://michalsujka-main-55d81d3.zuplo.app/docs/michalsujka" target="_blank" rel="noopener noreferrer">
        <div className="footer-icone pi pi-code" id="footer-code-icone"></div>
      </a>
      
    </div>
  );
}
