import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const AddressAutoComplete = ({
  id,
  card,
  setAddressLine1,
  setAddressLine2,
  setCity,
  setCountry,
  setPostalCode,
  disabled,
  loaded,
  enablePlacesAPI
}) => {
  //input state
  const [input, setInput] = useState('');

  //on mount, load google auto complete 
  useEffect(() => {
    if (!loaded || !enablePlacesAPI) return;
    const renderGoogle = () => {
      window[id] = new window.google.maps.places.Autocomplete(
        document.getElementById(id),
        {
          fields: ["address_components"]
        }
      );
        //functionally clear all state
      const clear = () => {
        setInput('')
        setAddressLine1('')
        setAddressLine2('')
        setCity('')
        setCountry('')
        setPostalCode('')
      }
      const handlePlaceSelect = () => {
        const place = window[id].getPlace();
        clear()
        let address = {};
        for (const component of place.address_components) {
          address[component.types[0]] = component.long_name;
        }
        const getStreetAddress = (country, route, street_number) => {

          if (country === "United States" || 
              country === "United Kingdom" ||
              country === "Canada" ||
              country === "Australia" ||
              country === "France" ) {
            return street_number + " " + route;
          }
          else {
            return route + " " + street_number;
          }
        }
        if ("premise" in address || "subpremise" in address) {
          setAddressLine1(address.premise ? address.premise : address.subpremise);
          if ("route" in address && "street_number" in address) {
            setAddressLine2(getStreetAddress(address.country, address.route, address.street_number));
          }
          else if ("route" in address) {
            setAddressLine2(address.route);
          }
        }
        else if ("route" in address && "street_number" in address) {
          setAddressLine1(getStreetAddress(address.country, address.route, address.street_number));
        }
        else if ("route" in address) {
          setAddressLine1(address.route);
        }
        if ("postal_town" in address) {
          setCity(address.postal_town);
        }
        else if ("locality" in address) {
          setCity(address.locality);
        }
        else if ("administrative_area_level_2" in address) {
          setCity(address.administrative_area_level_2);
        }
        if ("postal_code" in address) {
          setPostalCode(address.postal_code);
        }
        if ("country" in address) {
          setCountry(address.country);
        }
      }

      //listen for place change in input field
      window[id].addListener("place_changed", handlePlaceSelect)
    }

    //if places script is already found then listen for load and then renderGoogle
    let found = document.getElementById('placesScript') ? true : false;
    if (!found) {
      const script = document.createElement("script");
      script.id = 'placesScript';
      script.src = "https://maps.googleapis.com/maps/api/js?key=" + process.env.REACT_APP_PLACES_API_KEY + "&libraries=places";
      script.async = true;
      script.onload = () => renderGoogle();
      document.body.appendChild(script);
    }
    if (found) {
      if (document.getElementById('placesScript').readyState === 'complete') {
      renderGoogle();
      } else {
      document.getElementById('placesScript').addEventListener('load', renderGoogle);
      }
    }
  }, [id, loaded, setAddressLine1, setAddressLine2, setCity, setCountry, setPostalCode, enablePlacesAPI])

  return (
    <div>
      <div>
      {enablePlacesAPI && (
        <>
        {loaded ? <>
            <input
              id={id}
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder='Search your address here or enter below'
              disabled={disabled}
            />
        </> : <Skeleton count={1} />}
        </>)}
      </div>
      <div>
        {loaded ? <>
          <label>Street name, house name, and number</label>
          <input
            id="addressLine1"
            type="text"
            value={card.address_line1}
            onChange={(e) => setAddressLine1(e.target.value)}
            placeholder='10 Downing Street'
            disabled={disabled}
          />
          <input
            id="addressLine2"
            type="text"
            value={card.address_line2}
            onChange={(e) => setAddressLine2(e.target.value)}
            placeholder=''
            disabled={disabled}
          />
        </> : <Skeleton count={3} />}
      </div>
      <div>
        {loaded ? <>
          <label>City and postal code</label>
          <input
            id="city"
            type="text"
            value={card.city}
            onChange={(e) => setCity(e.target.value)}
            placeholder='London'
            disabled={disabled}
          />
          <input
            id="postalCode"
            type="text"
            value={card.postal_code}
            onChange={(e) => setPostalCode(e.target.value)}
            placeholder='SW1A 2AA'
            disabled={disabled}
          />
        </> : <Skeleton count={3} />}
      </div>
      <div>
        {loaded ? <>
          <label>Country</label>
          <input
            id="country"
            type="text"
            value={card.country}
            onChange={(e) => setCountry(e.target.value)}
            placeholder='United Kingdom'
            disabled={disabled}
          />
        </> : <Skeleton count={2} />}
      </div>
    </div>
  )
}

export default AddressAutoComplete;